<template>
  <div class="page-nav">
    <van-icon v-if="showBack" class="back" color="#222" name="arrow-left" @click="onBack" />
    <div class="title">{{title}}</div>
  </div>
</template>

<script>
export default {
  name: 'PageNav',
  props: {
    title: String,
    showBack: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    onBack() {
      this.$router.go(-1);
    },
  }
}
</script>

<style lang="scss">
.page-nav {
  position: relative;
  height: 45px;
  background: #fff;
  overflow: hidden;
  line-height: 45px;
  font-size: 16px;
  .back {
    position: absolute;
    top: 7.5px;
    left: 10px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    text-align: center;    
  }
}
</style>