<template>
  <div class="intention">
    <page-nav title="我的意向" />
    <div v-for="item in list" :key="item.id" class="item">
      <div class="header">
        <div class="store-name van-ellipsis">{{item.modifyTime}}</div>
        <div v-if="item.status == 1" class="state">等待卖家确认</div>
        <div v-else-if="item.status == 2" class="state">等待重新报价</div>
        <div v-else-if="item.status == 3" class="state">等待买家下单</div>
        <div v-else-if="item.status == 4" class="state">卖家已取消意向</div>
        <div v-else-if="item.status == 0" class="state">买家已取消意向</div>
        <div v-else-if="item.status == 9" class="state">已下单</div>
      </div>
      <div class="content">
        <div class="product-info" @click="onClickProd(item)">
          <img class="cover" :src="item.titleImg" />
          <div class="title van-multi-ellipsis--l2">{{item.title}}</div>
          <div class="count">x{{item.amount}}</div>
        </div>
        <div class="total-bar">
          <span>买家报价：</span>
          <span v-if="item.buyerQuote && item.buyerQuote > 0" class="price">￥{{item.buyerQuote}}</span>
          <span v-else>-</span>
        </div>
        <div class="total-bar">
          <span>卖家报价：</span>
          <span v-if="item.sellerQuote && item.sellerQuote + 0 > 0" class="price">￥{{item.sellerQuote}}</span>
          <span v-else>-</span>
        </div>
      </div>
      <div class="item-btn">
        <van-button v-if="item.status >= 1 && item.status <= 3" size="small" @click="onCancel(item)">取消意向</van-button>
        <van-button v-if="item.status >= 1 && item.status <= 3" plain type="info" size="small" custom-class="button" @click="onReQuote(item)">重新报价</van-button>
        <van-button v-if="item.status == 3" type="info" size="small" custom-class="button" @click="onConfirm(item)">确认下单</van-button>
      </div>
    </div>
    <van-empty v-show="!loading && list.length == 0" description="暂无意向商品记录" />
    <van-action-sheet v-model="showQuote" title="意向报价">
      <div class="intention-content">
        <van-form @submit="onSubmit" validate-trigger="onSubmit">
          <van-field
            v-model="quoteItem.amount"
            name="amount"
            label="购买数量"
            placeholder="请输入"
            type="digit"
            :rules="[{ required: true, message: '请输入购买数量' }]"
          />
          <van-field
            v-model="quoteItem.buyerQuote"
            name="quote"
            label="总报价(元)"
            placeholder="请输入"
            type="digit"
            :rules="[{ required: true, message: '请输入总报价' }]"
          />
          <van-button type="info" formType="submit" block round>提交</van-button>
        </van-form>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import Fetch from '../../common/fetch';
import { BuyerGetIntentionList, BuyerRequote, BuyerCancelIntention } from '../../common/interface';
import PageNav from '../../components/PageNav.vue';
import Vue from 'vue';
import { Dialog, Toast, ActionSheet, Form, Field } from 'vant';
Vue.use(Form);
Vue.use(Field);
Vue.use(Toast);
Vue.use(ActionSheet);

export default {
  name: 'Intention',
  components: { PageNav },
  data() {
    return {
      loading: false,
      curPage: 1,
      list: [],
      showQuote: false,
      quoteItem: {
        amount: '',
        quote: '',
      },
    };
  },
  mounted() {
    this.fetchList();
  },

  methods: {
    fetchList() {
      const page = this.curPage;
      const params = {
        pageNum: page,
        pageSize: 20,
      };

      Fetch.post(BuyerGetIntentionList, params).then((res) => {
        const resList = res.list || [];
        const finished = resList.length < 20;
        const newList = page == 1 ? resList : this.list.concat(resList);
        this.list = newList;
        this.finished = finished;
      }).catch((err) => {
        Toast.fail(err.msg || '请求失败');
      });
    },

    onClickProd(item) {
      this.$router.push('/product?id=' + item.productId);
    },

     // 取消意向
    onCancel(item) {
      Dialog.confirm({
        title: '确认要取消该产品意向吗？',
        cancelButtonText: '再想想',
      })
      .then(() => {
        const params = {
          intentionId: item.intentionId,
        }
        Fetch.post(BuyerCancelIntention, params).then(() => {
          this. curPage = 1;
          this.fetchList();
        }).catch(err => {
          Toast.fail(err.msg || '请求失败')
        });
      }).catch(() => {
      });
    },

    // 重新报价
    onReQuote(item) {
      this.showQuote =true;
      this.quoteItem = item;
    },

    onCloseQuoteAction() {
      this.showQuote = false;
    },

    onSubmit(values) {
      const quoteItem = this.quoteItem;
      if (values.amount <= 0 || values.amount > 99) {
        Toast.fail('请输入合适的购买数量');
        return;
      }
      if (values.amount > quoteItem.stockNum) {
        Toast.fail('最多只能购买' + quoteItem.stockNum + '个');
        return;
      }
      if (values.quote < 1 || values.quote > 9999999) {
        Toast.fail('请输入合适的报价');
        return;
      }
      // 提交请求
      const params = {
        intentionId: quoteItem.intentionId,
        price: values.quote,
        amount: values.amount
      };
      Fetch.post(BuyerRequote, params).then(() => {
        Dialog({ message: '报价提交成功，等待卖家处理' });
        this.showQuote = false;
        this.curPage = 1;
        this.fetchList();
      }).catch((err) => {
        const { msg } = err;
        Toast(msg);
      });
    },

    // 确认下单
    onConfirm() {

    },

  },
}
</script>

<style lang="scss" scoped>
.item {
  margin: 15px 10px;
  padding: 10px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 5%);
}

.header {
  line-height: 24px;
  position: relative;
  font-family: PingFang-SC-Medium;
  display: flex;
  align-items: center;
}
.header .store-logo {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.header .store-name {
  max-width: 90%;
  font-size: 16px;
  color: #333;
  padding-right: 12px;
  flex: 1;
}
.header .state {
  font-size: 14px;
  color: #999;
  flex-shrink: 0;
}

.product-info {
  margin-top: 8px;
  display: flex;
  overflow: hidden;
}

.product-info .cover {
  width: 75px;
  height: 75px;
  margin-right: 10px;
  border-radius: 6px;
  flex-shrink: 0;
}
.product-info .title {
  flex: 1;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333;
  line-height: 21px;
  align-self: center;
}
.product-info .count {
  margin-left: 8px;
  align-self: center;
  color: #999;
}


.total-bar {
  margin: 10px 0;
  position: relative;
  display: flex;
  font-size: 14px;
  color: #333;
  line-height: 21px;
}
.total-bar .price {
  font-size: 16px;
  color: #151515;
  vertical-align: bottom;
  font-weight: 600;
}

.item-btn {
  text-align: right;
}

.item-btn .button {
  margin-left: 8px;
}

.intention-content {
  padding: 0 15px 20px;
}
</style>